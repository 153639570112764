import HotelRoom from '../../components/views/hotel-room/hotel-room';
import { hotelRooms as hotelRoomContent } from '../../helpers/content';
import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const IndexPage = () => {
    const premiumHotel = hotelRoomContent.premiumHotel;
   
   return (
   <Layout>
        <SEO title="Premium Hotel Room Layout" />
        <HotelRoom hotelRoomContent={premiumHotel} />
    </Layout>
    )
}

export default IndexPage;